import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"

const CategoriesMain = props => {
  const { categories } = props
  return (
    <div id="asm-categories-main" className="container-fluid my-5">
      <div className="container">
        <div className="row">
          {categories.slice(0, 6).map((category, index) => {
            return (
              <div key={index} className="category-card col-6 col-sm text-center">
                <Link
                  to={`/blog/${category.node.slug}`}
                  className="p-0 d-block"
                  role="button"
                >
                  <div className="category-img-container rounded-circle p-3 mx-auto my-2">
                    <img
                      src={
                        category.node.icono_principal
                          ? category.node.icono_principal.publicURL
                          : "http://placehold.it/100x100"
                      }
                      alt={category.node.nombre}
                      className="mx-auto d-block"
                    />
                  </div>
                  <h5 className="category-heading text-blue-beta text-600">
                    {category.node.nombre}
                  </h5>
                </Link>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

CategoriesMain.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      node: PropTypes.shape({
        strapiId: PropTypes.string.isRequired,
        slug: PropTypes.string.isRequired,
        nombre: PropTypes.string.isRequired,
        icono_principal: PropTypes.shape({
          publicURL: PropTypes.string.isRequired,
        }),
      }),
    })
  ).isRequired,
}

CategoriesMain.defaultProps = {
  categories: [
    {
      node: {
        strapiId: "5fc67108b2b37d04310d0a30",
        slug: `nombre-categoria`,
        nombre: "Categoría",
        icono_principal: {
          publicURL: "http://placehold.it/100x100",
        },
      },
    },
    {
      node: {
        strapiId: "5fc67111b2b37d04310d0a31",
        slug: `nombre-categoria`,
        nombre: "Categoría",
        icono_principal: null,
      },
    },
    {
      node: {
        strapiId: "5fc6711ab2b37d04310d0a32",
        slug: `nombre-categoria`,
        nombre: "Categoría",
        icono_principal: null,
      },
    },
    {
      node: {
        strapiId: "5fc67123b2b37d04310d0a33",
        slug: `nombre-categoria`,
        nombre: "Categoría",
        icono_principal: null,
      },
    },
    {
      node: {
        strapiId: "5fc6712bb2b37d04310d0a34",
        slug: `nombre-categoria`,
        nombre: "Categoría",
        icono_principal: null,
      },
    },
  ],
}

export default CategoriesMain
