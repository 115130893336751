import React, { useState } from "react"
import PropTypes from "prop-types"
import addToMailchimp from "gatsby-plugin-mailchimp"
import { FacebookProvider, Page } from "react-facebook"

// Components
import CustomLink from "../elements/custom-link"
import ContentToHTML from "../elements/content-html"

const RightSidebar = props => {
  const { socialNetworks } = props

  const [email, setEmail] = useState("")
  const [result, setResult] = useState(null)
  const [submitText, setSubmitText] = useState("Enviar")

  const handleSubmit = async e => {
    setSubmitText("Validando...")
    e.preventDefault()
    const res = await addToMailchimp(email)
    setResult(res)
    setSubmitText("Enviar")
  }

  return (
    <div id="asm-right-sidebar" className="col-12 col-sm-4 pl-sm-5">
      <div className="row">
        <div className="col-12 my-3 sidebar-social-links">
          {socialNetworks.map((item, index) => {
            return (
              <CustomLink link={item} customClass="mr-3" key={index}>
                <img src={item.icono && item.icono.publicURL ? item.icono.publicURL : "http://placehold.it/75x75"} alt={item.nombre} />
              </CustomLink>
            )
          })}
        </div>
        <div className="col-12 my-3 form-subscribe-blog">
          <form
            className="bg-gray-alpha position-relative text-center px-3 py-4"
            onSubmit={handleSubmit}
          >
            <div className="form-group mb-1">
              <h4 className="text-700">Suscríbete</h4>
              <label htmlFor="inputBlogEmail">
                Recibe las últimas noticias vía correo electrónico
              </label>
              <input
                type="email"
                aria-label="inputBlogEmail"
                className="form-control border-0 rounded-pill text-center py-4"
                id="inputBlogEmail"
                aria-describedby="blogEmailHelp"
                placeholder="escribe tu correo electrónico"
                onChange={e => setEmail(e.target.value)}
              />
              {result ? (
                result.result === "success" ? (
                  <small id="blogEmailHelp" className="form-text text-success">
                    Se ha agregado exitosamente a nuestro Blog.
                  </small>
                ) : (
                  <small id="blogEmailHelp" className="form-text text-danger">
                    <ContentToHTML content={result.msg} />
                  </small>
                )
              ) : (
                <small id="blogEmailHelp" className="form-text text-muted">
                  Datos protegidos bajo la ley 1581 de 2012
                </small>
              )}
            </div>
            <button
              type="submit"
              className="btn btn-asm-black-alpha rounded-pill px-5 py-2"
            >
              {submitText}
            </button>
          </form>
        </div>
        <div className="col-12 my-3 sidebar-widget">
          <div className="social-feed mt-4">
            <FacebookProvider appId="220141319793057" wait={true}>
              <Page
                href="https://www.facebook.com/asmetsaludeps/"
                tabs="timeline"
                height="400px"
                smallHeader={true}
                adaptContainerWidth={true}
              />
            </FacebookProvider>
          </div>
        </div>
      </div>
    </div>
  )
}

RightSidebar.propTypes = {
  socialNetworks: PropTypes.arrayOf(
    PropTypes.shape({
      nombre: PropTypes.string.isRequired,
      tipo: PropTypes.string.isRequired,
      link_id: PropTypes.string.isRequired,
      icono: PropTypes.shape({
        publicURL: PropTypes.string.isRequired,
      }).isRequired,
    })
  ).isRequired,
}

RightSidebar.defaultProps = {
  socialNetworks: [
    {
      nombre: "Twitter",
      tipo: "Externo",
      link_id: "/",
      icono: {
        publicURL:
          "/static/70a607edb2a909936b47d4dd978a4209/e270282bad4ab2907233f099d1db3a9c.png",
      },
    },
  ],
}

export default RightSidebar
