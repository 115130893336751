import React from "react"

// Layout
import Layout from "../components/layout"
// Components
import CategoriesTop from "../components/blog/categories-top"
import CategoriesMain from "../components/blog/categories-main"
import CategoriesGrid from "../components/blog/categories-grid"
import RightSidebar from "../components/blog/right-sidebar"
// Hooks
import { useAsmetHome } from "../hooks/use-asmet-home"
import { useBlogCategories } from "../hooks/use-blog-categories"

const logoCuidarmeAmarme = require("../assets/images/logos/cuidarme-amarme.png")

const BlogPage = () => {
  const asmetHome = useAsmetHome()
  const allCategories = useBlogCategories()
  const mainCategories = allCategories.filter(function (category) {
    return category.node.destacado
  })
  const nonMainCategories = allCategories.filter(function (category) {
    return !category.node.destacado
  })
  const topCategories = nonMainCategories.slice(0, 2)
  const gridCategories = nonMainCategories.filter(function (el) {
    return !topCategories.includes(el)
  })

  return (
    <Layout
      simpleLayout={true}
      meta={{
        og_tipo: "blog",
        og_titulo: "Blog",
      }}
    >
      <div id="asm-blog-header" className="container my-5">
        <div className="row justify-content-center">
          <div className="col-8 col-sm-4">
            <img
              src={logoCuidarmeAmarme}
              alt="Cuidarme es Amarme"
              className="d-block w-100"
            />
          </div>
        </div>
      </div>
      <CategoriesMain categories={mainCategories} />
      <CategoriesTop categories={topCategories} />
      <div className="container my-5">
        <div className="row">
          <CategoriesGrid categories={gridCategories} />
          <RightSidebar socialNetworks={asmetHome.redes_sociales}/>
        </div>
      </div>
    </Layout>
  )
}

export default BlogPage
