import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"

const CategoriesGrid = props => {
  const { categories } = props
  return (
    <div id="asm-categories-grid" className="col-12 col-sm-8">
      <div className="row">
        {categories.map((category, i) => {
          return (
            <div
              className={((i + 1) % 3 === 1 ? "col-12" : "col-12 col-sm-6") + " my-3"}
              key={i}
            >
              <div className="card shadow-lg border-0 rounded-0 h-100">
                <img
                  src={
                    category.node.imagen_principal
                      ? category.node.imagen_principal.publicURL
                      : (i + 1) % 3 === 1
                      ? "http://placehold.it/800x465?text=" +
                        category.node.nombre
                      : "http://placehold.it/465x465?text=" +
                        category.node.nombre
                  }
                  className="card-img rounded-0"
                  alt={category.node.nombre}
                />
                <div className="card-body d-flex flex-column justify-content-end text-right">
                  <h2 className="card-title text-white-alpha pr-4">{category.node.nombre}</h2>
                  <Link
                    to={`/blog/${category.node.slug}`}
                    className="btn btn-asm-red-alpha stretched-link ml-auto px-5"
                  >Ver más</Link>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

CategoriesGrid.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      node: PropTypes.shape({
        strapiId: PropTypes.string.isRequired,
        slug: PropTypes.string.isRequired,
        nombre: PropTypes.string.isRequired,
        imagen_principal: PropTypes.shape({
          publicURL: PropTypes.string.isRequired,
        }),
      }),
    })
  ).isRequired,
}

CategoriesGrid.defaultProps = {
  categories: [
    {
      node: {
        nombre: `Categoría`,
        slug: `nombre-categoria`,
        strapiId: "5fc67108b2b37d04310d0a30",
        imagen_principal: {
          publicURL: `http://placehold.it/800x465?text=Categoría`,
        },
      },
    },
    {
      node: {
        nombre: `Categoría`,
        slug: `nombre-categoria`,
        strapiId: "5fc67108b2b37d04310d0a30",
        imagen_principal: {
          publicURL: `http://placehold.it/465x465?text=Categoría`,
        },
      },
    },
    {
      node: {
        nombre: `Categoría`,
        slug: `nombre-categoria`,
        strapiId: "5fc67108b2b37d04310d0a30",
        imagen_principal: {
          publicURL: `http://placehold.it/465x465?text=Categoría`,
        },
      },
    },
    {
      node: {
        nombre: `Categoría`,
        slug: `nombre-categoria`,
        strapiId: "5fc67108b2b37d04310d0a30",
        imagen_principal: {
          publicURL: `http://placehold.it/800x465?text=Categoría`,
        },
      },
    },
    {
      node: {
        nombre: `Categoría`,
        slug: `nombre-categoria`,
        strapiId: "5fc67108b2b37d04310d0a30",
        imagen_principal: {
          publicURL: `http://placehold.it/465x465?text=Categoría`,
        },
      },
    },
    {
      node: {
        nombre: `Categoría`,
        slug: `nombre-categoria`,
        strapiId: "5fc67108b2b37d04310d0a30",
        imagen_principal: {
          publicURL: `http://placehold.it/465x465?text=Categoría`,
        },
      },
    },
  ],
}

export default CategoriesGrid
