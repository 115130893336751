import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"

const CategoriesTop = props => {
  const { categories } = props
  return (
    <div id="asm-categories-top" className="container-fluid my-3">
      <div className="row">
        <div className="col-12 col-sm-5 card-category-left pl-sm-0 pr-sm-4 mb-4 mb-sm-0">
          <div className="card shadow-lg border-0 rounded-0 h-100">
            <img
              src={
                categories[0].node.imagen_principal
                  ? categories[0].node.imagen_principal.publicURL
                  : "http://placehold.it/465x465?text=" +
                    categories[0].node.nombre
              }
              className="card-img rounded-0"
              alt={categories[0].node.nombre}
            />
            <div className="card-body d-flex flex-column justify-content-end text-right">
              <h2 className="card-title text-white-alpha pr-4">{categories[0].node.nombre}</h2>
              <Link
                to={`/blog/${categories[0].node.slug}`}
                className="btn btn-asm-red-alpha stretched-link ml-auto px-5"
              >
                Ver más
              </Link>
            </div>
          </div>
        </div>

        <div className="col-12 col-sm-7 card-category-right pr-sm-0 pl-sm-4 mb-1 mb-sm-0">
          <div className="card shadow-lg border-0 rounded-0 h-100">
            <img
              src={
                categories[1].node.imagen_principal
                  ? categories[1].node.imagen_principal.publicURL
                  : "http://placehold.it/800x465?text=" +
                    categories[1].node.nombre
              }
              className="card-img rounded-0"
              alt={categories[1].node.nombre}
            />
            <div className="card-body d-flex flex-column justify-content-end text-right">
              <h2 className="card-title text-white-alpha pr-4">{categories[1].node.nombre}</h2>
              <Link
                to={`/blog/${categories[1].node.slug}`}
                className="btn btn-asm-red-alpha stretched-link ml-auto px-5"
              >
                Ver más
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

CategoriesTop.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      node: PropTypes.shape({
        strapiId: PropTypes.string.isRequired,
        slug: PropTypes.string.isRequired,
        nombre: PropTypes.string.isRequired,
        imagen_principal: PropTypes.shape({
          publicURL: PropTypes.string.isRequired,
        }),
      }),
    })
  ).isRequired,
}

CategoriesTop.defaultProps = {
  categories: [
    {
      node: {
        strapiId: "5fc67111b2b37d04310d0a31",
        slug: `nombre-categoria`,
        nombre: "Categoría",
        imagen_principal: {
          publicURL: `http://placehold.it/465x465?text=Categoría`,
        },
      },
    },
    {
      node: {
        strapiId: "5fc6711ab2b37d04310d0a32",
        slug: `nombre-categoria`,
        nombre: "Categoría",
        imagen_principal: {
          publicURL: `http://placehold.it/800x465?text=Categoría`,
        },
      },
    },
  ],
}

export default CategoriesTop
